<template>
  <section class="login">
    <div class="grid-x">
      <div class="cell auto">
        <form @submit.prevent="login">
          <h4 class="title">Se connecter</h4>
          <div class="row">
            <app-input
              label="Email"
              v-model="email"
              placeholder="Email..."
              type="email"
              :hasSucceed="isFormInvalid ? 'error' : null"
              :msg="isFormInvalid ? errorMessage : null"
              required />
          </div>
          <div class="">
            <app-input
              label="Mot de passe"
              v-model="password"
              placeholder="Mot de passe..."
              type="password"
              :hasSucceed="isFormInvalid ? 'error' : null"
              :msg="isFormInvalid ? errorMessage : null"
              required />
          </div>
          <router-link :to="{ name: 'lost-password' }" class="forgotten-password">Mot de passe oublié ?</router-link>
          <app-button type="submit" theme="primary" size="large">Se connecter</app-button>
          <router-link class="sign-in" :to="{ name: 'register' }">S'inscrire</router-link>
        </form>
      </div>
      <div class="cell auto presentation">
        <div class="blur"></div>
        <div class="content">
          <logo-bird class="logo"/>
          <h6>Le plus court chemin entre vous et votre maison</h6>
          <router-link class="back-link" :to="{ name: 'home' }">
            <app-button theme="secondary">Retour au site</app-button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import logoBird from '@/assets/logos/bird.svg?inline';

export default {
  components: {
    'logo-bird': logoBird,
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      isFormInvalid: false,
    };
  },
  methods: {
    async login() {
      this.errorMessage = '';
      try {
        await authApi.login(this.email, this.password);
        this.isFormInvalid = false;
        this.$notification.show({ text: 'Connexion réussie' });

        // Pour qu'on puisse se connecter depuis le menu dans le config et que les pages connectées s'affichent (fix rapide)
        this.$router.push({ name: 'account', params: { tab: 'mes-projets' } });
      } catch (e) {
        this.isFormInvalid = true;
        if (e.response.status === 400) {
          this.errorMessage = 'Aucun compte lié à cet identifiant';
        } else if (e.response.status === 401) {
          this.errorMessage = 'Identifiant ou mot de passe incorrect';
        } else {
          this.errorMessage = 'Une erreur est survenue';
        }
      }
    },
  },
};

</script>

<style lang="sass" scoped>
  .login
    margin-top: 70px
    > .grid-x
      min-height: 80vh
    .cell
      padding: 40px
    .title
      color: black
      font-size: 20px
      font-weight: 500
    .row
      @include row
      width: 100%
    form
      display: flex
      flex-direction: column
      justify-content: center
      height: 100%
      max-width: 400px
      margin: auto
      h4
        color: $body-color
      a
        &.forgotten-password
          align-self: flex-end
          margin-top: 19px
          margin-bottom: 30px
        &.sign-in
          margin-top: 18px
      button
        width: fit-content
        padding-left: 20px
        padding-rigth: 20px
    .presentation
      background-image: url(../../assets/img/pages/background-house.jpeg)
      background-size: cover
      background-position: center
      position: relative
      @media (max-width: 1080px)
        display: none
      .blur
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        z-index: 1
        @include black-filter
      .content
        height: 100%
        z-index: 2
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .logo
          margin-bottom: 20px
      h6
        color: $white
      .back-link
        margin-top: 30px
        margin-bottom: 50px
        text-decoration: none
</style>
